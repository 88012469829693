import React, { useState, useEffect } from 'react'


export default function Details() {

    return (
        <>
            <h1>Details</h1>
        </>
    )
}

